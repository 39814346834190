// graph
import { useMutation } from '@apollo/client'
import { LOGIN_USER } from '../graphQL/mutations'
import { GqlLogUserData, GqlLogUserVars } from '../graphQL/graphTypes'

// graph
function useSharedMutations() {
  const [login, { loading: loginLoading }] = useMutation<GqlLogUserData, GqlLogUserVars>(
    LOGIN_USER
  )

  return {
    login,
    loginLoading
  }
}

export default useSharedMutations
