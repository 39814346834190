/**
 * extracts URL parameters from a search string
 * @function getParams
 * @param {string} search - The search string from which to extract URL parameters
 * @returns {URLSearchParams} An instance of URLSearchParams containing the parsed parameters
 */
export const getParams = (search: string) => new URLSearchParams(search)

/**
 * Validates if the string is a valid email address
 * @function validateEmail
 * @param {string} email - The email address to validate
 * @returns {RegExpMatchArray} A match array if the email
 */
export const validateEmail = (email: string) => {
  // eslint-disable-next-line
  return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
}
