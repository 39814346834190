import { useState, useEffect } from 'react'
import { Select, Spacer, Box, useMediaQuery } from '@matchplat/crono'

// translations
import i18n from '../i18n'

// graph
import { useQuery } from '@apollo/client'
import { VAT_ID_BY_COUNTRIES } from '../graphQL/queries'

// translations
import { useTranslation } from 'react-i18next'

// types
import { SelectCountriesVatProps, VatIdVatInfoType } from './componentsProps'
import { GqlVatIdByCountriesData } from '../graphQL/graphTypes'

const SelectCountriesVat = ({ setVatInfo, vatInfo, setPlaceholderVatInfo }: SelectCountriesVatProps) => {
  // media
  const { mediaDownFrom } = useMediaQuery()

  // local state
  const [selectedCountry, setSelectedCountry] = useState<{ value: string, label: string } | undefined>(undefined)

  // translations
  const { t } = useTranslation<string[]>(['labels'])

  // mutation error
  const onMutationError = (error: any) => {
    console.error(error)
  }

  // queries
  const { data: vatIdByCountriesGql } = useQuery<GqlVatIdByCountriesData>(
    VAT_ID_BY_COUNTRIES,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      onError: onMutationError
    }
  )

  // set placeholder for input VAT
  useEffect(() => {
    if (vatInfo) {
      let format = ''
      vatIdByCountriesGql?.countries
        .filter(country => (country.iso2 === selectedCountry?.value && country.vatInfo && country.vatInfo.length > 0))
        .forEach(country => {
          country.vatInfo?.forEach((localVatInfo: VatIdVatInfoType) => {
            if ((localVatInfo.id === vatInfo.value)) {
              format = localVatInfo.format
            }
          })
        })
      setPlaceholderVatInfo(format)
    }
  }, [vatInfo?.value])

  // generate options for VAT
  const options: { value: string, label: string }[] = []
  vatIdByCountriesGql?.countries
    .filter(country => (country.iso2 === selectedCountry?.value && country.vatInfo && country.vatInfo.length > 0))
    .forEach(country => {
      country.vatInfo?.forEach((vatInfo: VatIdVatInfoType) => {
        (
          options.push({
            value: vatInfo?.id,
            label: `${i18n.language === 'it' ? vatInfo?.descriptionIta : vatInfo?.descriptionEng}`
          })
        )
      })
    })

  // ISO 2 COUNTRIES
  const countries = vatIdByCountriesGql?.countries
    .filter(country => country.vatInfo && country.vatInfo.length > 0).map(country => ({
      value: country.iso2,
      label: country.iso2
    }))

  return (
    <Box column={mediaDownFrom('md')}>
      <Select
        style={{ width: mediaDownFrom('md') ? '100%' : '25%' }}
        textSize='subArticle'
        menuPlacement='auto'
        isClearable={false}
        options={countries}
        onChange={(e: any) => { setSelectedCountry(e); setVatInfo({ value: undefined, label: '' }) }}
        value={selectedCountry}
        label={t('common:form.country')}
      />
      <Spacer half />
      <Select
        isDisabled={!selectedCountry?.value}
        style={{ width: mediaDownFrom('md') ? '100%' : '75%' }}
        textSize='subArticle'
        menuPlacement='auto'
        isClearable={false}
        options={options}
        onChange={(e: any) => { setVatInfo(e) }}
        value={vatInfo}
        label={t('common:form.vat_id')}
      />
    </Box>
  )
}

export default SelectCountriesVat
