/* eslint-disable react/no-children-prop */
import { Suspense } from 'react'
import { Box, PageLoading, useMediaQuery } from '@matchplat/crono'

// router
import { HashRouter as Router, Route, Routes } from 'react-router-dom'

// context
import { RouterProvider } from './context/RouterContext'

// forms
import TestBounce from './TestBounce'
import Login from './forms/authentication/Login'
import SignUpFromInvitation from './forms/authentication/SignUpFromInvtiation'
import RequestReset from './forms/password/RequestReset'
import Reset from './forms/password/Reset'
import TopMenu from './menu/TopMenu'
import ImageCycle from './animations/ImageCycle'
import SignUp from './forms/authentication/SignUp'

/**
 * Render component for managing routes and rendering different forms
 * @component Render
 * @returns {JSX.Element} The rendered Render component
 */
const Render = (): JSX.Element => {
  // media
  const { mediaUpFrom } = useMediaQuery()

  return (
    <RouterProvider>
      <>
        <TopMenu />
        <Box style={{ minHeight: '100vh' }} alignItems="center">
          {/* FORMS LEFT SIDE */}
          {/* USING TEST BOUNCE FOR EVERY POSSIBLE ROUTE */}
          <Box width={mediaUpFrom('md') ? '50%' : '100%'} justifyContent="center" style={{ marginTop: mediaUpFrom('md') ? '-40px' : '-120px' }}>
            <Router>
              <Suspense fallback={<PageLoading />}>
                <Routes>
                  <Route path="/" element={<TestBounce children={<Login />} />} />
                  <Route path="/signUpFromInvitation" element={<TestBounce children={<SignUpFromInvitation />} />} />
                  <Route path="/signUp" element={<TestBounce children={<SignUp />} />} />
                  <Route path="/requestReset" element={<TestBounce children={<RequestReset />} />} />
                  <Route path="/resetToken" element={<TestBounce children={<Reset />} />} />
                </Routes>
              </Suspense>
            </Router>
          </Box>
          {/* IMAGE RIGHT SIDE */}
          {mediaUpFrom('md') &&
            <Box justify="stretch" align="stretch">
              <ImageCycle />
            </Box>
          }
        </Box>
      </>
    </RouterProvider>

  )
}

export default Render
