import { gql } from '@apollo/client'

export const SIGN_UP_LITE = gql`
mutation SignUpLite($email: String!, $password: String!, $vatId: String, $taxCodeType: String, $companyId: String, $language: String, $isMarketingNotificationsEnabled: Boolean) {
  signUpLite(email: $email, password: $password, vatId: $vatId, taxCodeType: $taxCodeType, companyId: $companyId, language: $language, isMarketingNotificationsEnabled: $isMarketingNotificationsEnabled) {
    success
  }
}
`

export const SIGN_UP_FROM_INVITATION = gql`
mutation SignUpByInvitation($email: String!, $name: String!, $surname: String!, $password: String!, $language: String!, $phone: String) {
  signUpByInvitation(email: $email, name: $name, surname: $surname, password: $password, language: $language, phone: $phone) {
    success
  }
}
`

export const REQ_PASS_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    resetPasswordToken(email: $email) {
      success
    }
  }
`

export const PASS_RESET = gql`
  mutation ResetPassword(
    $userId: String!
    $passwordResetToken: String!
    $newPassword: String!
  ) {
    resetPassword(
      userId: $userId,
      passwordResetToken: $passwordResetToken,
      newPassword: $newPassword
    ) {
      success
    }
  }
`

export const LOGIN_USER = gql`
  mutation LogUser($email: String!, $password: String!, $rememberMe: Boolean!) {
    signIn(email: $email, password: $password, rememberMe: $rememberMe) {
      accessToken
      refreshToken
    }
  }
`
