import { useState } from 'react'
import { Button, Input, Spacer, Typography, Box, uri, useMediaQuery } from '@matchplat/crono'

// translations
import i18n from '../../i18n'

// hooks
import { Resolver, useForm } from 'react-hook-form'

// graph
import { useMutation } from '@apollo/client'
import { SIGN_UP_FROM_INVITATION } from '../../graphQL/mutations'

// translations
import { useTranslation } from 'react-i18next'

// router
import { useNavigate, useLocation } from 'react-router-dom'

// utils
import useSharedMutations from '../../utils/useSharedMutations'
import { getParams, validateEmail } from '../../utils/authenticationUtils'

// types
import { SignUpFormValuesProps } from '../formsProps'

/**
 * SignUpFromInvitation component for user registration
 * @component SignUpFromInvitation
 * @returns {JSX.Element} The rendered SignUpFromInvitation component
 */
const SignUpFromInvitation = (): JSX.Element => {
  // router
  const navigate = useNavigate()

  // media
  const { mediaUpFrom } = useMediaQuery()

  // translations
  const { t } = useTranslation(['translations', 'common'])

  // local state
  const [isError, setIsError] = useState(false)
  const location = useLocation()
  const params = getParams(location.search)

  const invitationEmail = params.get('invitationEmail')

  // shared  mutations
  const { login } = useSharedMutations()

  const onMutationError = (error: any) => {
    console.error(error)
    setIsError(true)
  }

  const [signUpFromInvitation, { loading: mutationLoading }] = useMutation(
    SIGN_UP_FROM_INVITATION,
    {
      onError: onMutationError
    }
  )

  /**
   * Resolver function for form validation
   * @function resolver
   * @param {SignUpFormValuesProps} values - The form values
   * @returns {Object} An object containing form values and errors
   */
  const resolver: Resolver<SignUpFormValuesProps> = async (values = {}) => {
    const errors: SignUpFormValuesProps = {}
    !values.email && (errors.email = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.email') }
      )
    })
    values.email && !validateEmail(values.email.toString()) && (errors.email = {
      type: 'required',
      message: t('common:validation.email_format_not_valid')
    })
    values.password !== values.matchPass && (errors.matchPass = {
      type: 'required',
      message: t('common:validation.matchPass')
    })
    !values.matchPass && !values.password && (errors.matchPass = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.passConfirm') }
      )
    })
    !values.name && (errors.name = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.name') }
      )
    })
    !values.password && (errors.password = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.password') }
      )
    })
    !values.surname && (errors.surname = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.surname') }
      )
    })
    return { values, errors }
  }

  const defaultValues = {
    language: i18n.language,
    phone: '',
    acceptedNewsletter: false,
    email: invitationEmail || ''
  }

  const {
    // control,
    formState: { errors },
    handleSubmit,
    register
  } = useForm<SignUpFormValuesProps>({ defaultValues, resolver })

  /**
   * Handles form submission
   * @async onSubmit
   * @param {AddUserVariablesProps} variables - The form variables
   * @returns {Promise<void>}
   */
  const onSubmit = async (variables: any): Promise<void> => {
    signUpFromInvitation({
      variables,
      onCompleted() {
        login({
          variables: {
            email: variables.email,
            password: variables.password,
            rememberMe: false
          },
          onCompleted() {
            window.location.replace(uri.getAppUri('explore'))
          }
        })
      }
    })
  }

  return (
    <Box column width={mediaUpFrom('md') ? '65%' : '95%'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography size="title" weight='bold'>
          {t('translations:titles.signupForm')}
        </Typography>
        <Spacer vertical />
        <Input
          textSize='subArticle'
          errors={errors}
          label={t('common:form.name')}
          labelSize={mediaUpFrom('md') ? 'article' : 'subArticle'}
          placeholder="Matchy"
          type="name"
          name="name"
          hookFormRegister={{ ...register('name') }}
        />
        <Input
          textSize='subArticle'
          errors={errors}
          label={t('common:form.surname')}
          labelSize={mediaUpFrom('md') ? 'article' : 'subArticle'}
          placeholder="TheBot"
          type="surname"
          name="surname"
          hookFormRegister={{ ...register('surname') }}
        />
        <Input
          textSize='subArticle'
          errors={errors}
          label={t('common:form.email')}
          labelSize={mediaUpFrom('md') ? 'article' : 'subArticle'}
          placeholder="b2b@matchplat.com"
          type="email"
          name="email"
          hookFormRegister={{ ...register('email') }}
          disabled={!!invitationEmail?.length}
        />
        <Input
          textSize='subArticle'
          errors={errors}
          label={t('common:form.password')}
          labelSize={mediaUpFrom('md') ? 'article' : 'subArticle'}
          placeholder="password"
          type="password"
          name="password"
          hookFormRegister={{ ...register('password') }}
        />
        <Input
          textSize='subArticle'
          errors={errors}
          label={t('common:form.passConfirm')}
          labelSize={mediaUpFrom('md') ? 'article' : 'subArticle'}
          placeholder="password"
          type="password"
          name="matchPass"
          hookFormRegister={{ ...register('matchPass') }}
        />
        <Input
          textSize='subArticle'
          errors={errors}
          label={t('common:form.phone')}
          labelSize={mediaUpFrom('md') ? 'article' : 'subArticle'}
          placeholder={t('common:form.phone')}
          name="phone"
          hookFormRegister={{ ...register('phone') }}
        />
        <Button
          color="secondary"
          isBlock
          isFull
          noMargin
          textAlign='center'
          text={t('common:button.send')}
          isLoading={mutationLoading}
          isError={isError}
          doneText={t('common:button.done')}
          errorText={t('common:button.error')}
        />
      </form>
      <Spacer vertical half />
      <Box column alignItems='flex-end'>
        <Typography size="subArticle" style={{ cursor: 'pointer' }} color='secondary' onClick={() => navigate('/')}>{t('translations:actions.signIn')}</Typography>
      </Box>
    </Box>
  )
}

export default SignUpFromInvitation
