import { useState, useContext } from 'react'
import { Button, Input, Spinner, Spacer, Typography, Box, useCronoTheme } from '@matchplat/crono'

// translations
import { useTranslation } from 'react-i18next'

// context
import { RouterContext } from '../../context/RouterContext'

// graph
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { TOKEN_VALIDATION } from '../../graphQL/queries'
import { PASS_RESET } from '../../graphQL/mutations'

// hooks
import { Resolver, useForm } from 'react-hook-form'

// utils
import { getParams } from '../../utils/authenticationUtils'

// router
import { useNavigate, useLocation } from 'react-router-dom'

import {
  ResetFormValuesProps
} from '../formsProps'
import { GqlResetPasswordData, GqlResetPasswordVars, GqlTokenValidationData, GqlTokenValidationVars } from '../../graphQL/graphTypes'

/**
 * Reset component for handling password reset
 * @component Reset
 * @returns {JSX.Element} The rendered Reset component
 */
const Reset = (): JSX.Element => {
  // theme
  const theme = useCronoTheme()
  // context
  const context = useContext(RouterContext)
  const location = useLocation()
  // Check url for token and userId existence
  const params = getParams(location.search)

  // router
  const navigate = useNavigate()

  // translations
  const { t } = useTranslation(['translations', 'common'])

  // local state
  const [isPasswordRestored, setIsPasswordRestored] = useState(false)
  const [isResetError, setIsResetError] = useState(false)
  const [isValidationError, setIsValidationError] = useState(false)

  const token = params.get('token')!
  const userId = params.get('userId')!

  // Apollo error
  const onValidationError = (error: ApolloError) => {
    setIsValidationError(true)
    console.error(error)
  }

  const {
    loading: validationLoadingGql,
    data: validationData
  } = useQuery<GqlTokenValidationData, GqlTokenValidationVars>(
    TOKEN_VALIDATION,
    {
      variables: { userId, resetPasswordToken: token },
      onError: (e) => onValidationError(e),
      onCompleted: () => context.setIsLoading(false)
    }
  )

  // mutation completed
  const onMutationCompleted = () => { setIsPasswordRestored(true) }

  // mutation error
  const onMutationError = (error: ApolloError) => {
    console.error(error)
    setIsResetError(true)
  }

  // local state
  const [resetPassword, { loading: mutationLoading }] = useMutation<GqlResetPasswordData, GqlResetPasswordVars>(
    PASS_RESET,
    {
      onCompleted: onMutationCompleted,
      onError: onMutationError
    }
  )

  /**
   * Handles form submission for resetting the password
   * @async onSubmit
   * @param {Object} values - The form values
   * @param {string} values.password - The new password
   * @returns {Promise<void>}
   */
  const onSubmit = async ({ password }: { password: string }): Promise<void> => {
    resetPassword(
      { variables: { userId, passwordResetToken: token, newPassword: password } }
    )
  }

  /**
   * Resolver function for form validation
   * @async resolver
   * @param {Object} values - The form values
   * @returns {Object} An object containing form values
   */
  const resolver: Resolver<ResetFormValuesProps> = async (values = {}) => {
    const errors: ResetFormValuesProps = {}

    values.password !== values.matchPass && (errors.matchPass = {
      type: 'required',
      message: t('common:validation.matchPass')
    })
    !values.matchPass && !values.password && (errors.matchPass = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.passConfirm') }
      )
    })
    !values.password && (errors.password = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.password') }
      )
    })

    return { values, errors }
  }

  const {
    formState: { errors },
    handleSubmit,
    register
  } = useForm<ResetFormValuesProps>({ resolver })

  return validationLoadingGql
    ? <Spinner />
    : isValidationError
      ? (
        <Typography size="subTitle">
          {t('translations:subTitles.queryError')}
        </Typography>
        )
      : validationData?.resetPasswordTokenValidation
        ? (isPasswordRestored
            ? (
            <Box style={{ padding: theme.paddingsMargins.themePadding }} column width="65%">
              <Typography size="subTitle">
                {t('translations:subTitles.passRestored')}
              </Typography>
              <Spacer />
              <Typography size="subArticle" style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>{t('translations:actions.signIn')}</Typography>
            </Box>)
            : <Box column width="65%">
            {/* @ts-ignore */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography size="title">
                {t('translations:titles.resetForm')}
              </Typography>
              <Spacer vertical />
              <Input
                errors={errors}
                label={t('common:form.password')}
                placeholder="password"
                type="password"
                hookFormRegister={{ ...register('password') }}
              />
              <Input
                errors={errors}
                label={t('common:form.passConfirm')}
                placeholder="password"
                type="password"
                hookFormRegister={{ ...register('matchPass') }}
              />
              <Button
                isBlock
                textAlign='center'
                isFull
                color="secondary"
                noMargin
                text={t('common:button.send')}
                isLoading={mutationLoading}
                isError={isResetError}
                doneText={t('common:button.done')}
                errorText={t('common:button.error')}
              />
            </form>
          </Box>
          )
        : (
          <Typography size="subTitle">
            {t('translations:subTitles.invalidLink')}
          </Typography>
          )
}

export default Reset
