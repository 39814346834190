import { gql } from '@apollo/client'

export const VAT_ID_BY_COUNTRIES = gql`
query Countries {
  countries {
    iso2
    vatInfo {
      descriptionEng
      descriptionIta
      format
      id
    }
  }
}
`

export const TOKEN_VALIDATION = gql`
  query TokenValidation(
    $userId: String!
    $resetPasswordToken: String!
  ) {
    resetPasswordTokenValidation(
      userId: $userId
      resetPasswordToken: $resetPasswordToken
    )
  }
`

export const COMPANY_INFO_BY_VAT_ID = gql`
query CompanyInfoByVatId($vatId: String) {
  companyInfoByVatId(vatId: $vatId) {
    companyInternationalName
    companyCity
    companyId
  }
}
`
