/* eslint-disable indent */
import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Input, Spacer, Typography, Box, useMediaQuery, Radio, Card, uri, Checkbox, useCronoTheme } from '@matchplat/crono'

// translations
import i18n from '../../i18n'

// graph
import { useMutation, useLazyQuery } from '@apollo/client'
import { SIGN_UP_LITE } from '../../graphQL/mutations'
import { COMPANY_INFO_BY_VAT_ID } from '../../graphQL/queries'

// translations
import { useTranslation } from 'react-i18next'

// router
import { useNavigate } from 'react-router-dom'

// components
import SelectCountriesVat from '../../components/SelectCountriesVat'

// types
import useDebounce from '../../hooks/useDebounce'
import useSharedMutations from '../../utils/useSharedMutations'
import { Company } from './authenticationProps'
import { GqlCompanyInfoByVatIdData, GqlCompanyInfoByVatIdVars, GqlSignUpLiteData, GqlSignUpLiteVars } from '../../graphQL/graphTypes'

/**
 * SignUpForm component for user registration
 * @component SignUpForm
 * @returns {JSX.Element} The rendered SignUpForm component
 */
const SignUp = (): JSX.Element => {
  // router
  const navigate = useNavigate()

  // media
  const { mediaUpFrom } = useMediaQuery()

  // theme
  const theme = useCronoTheme()

  // translations
  const { t } = useTranslation(['translations', 'common'])

  // local state
  const [isError, setIsError] = useState(false)
  const [vatId, setVatId] = useState<string>('')
  const [companySelected, setCompanySelected] = useState<Company>()
  const [showPassword, setShowPassword] = useState(false)
  const [isVatVisible, setIsVatVisible] = useState<boolean>(true)
  const [vatInfo, setVatInfo] = useState<{ value: string | undefined, label: string } | undefined>(undefined)
  const [placeholderVatInfo, setPlaceholderVatInfo] = useState<string | undefined>(undefined)
  const [isMarketingNotificationsEnabled, setIsMarketingNotificationsEnabled] = useState<boolean>(false)
  const [isUserAgreement, setIsUserAgreement] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  // shared  mutations
  const { login } = useSharedMutations()

  const onMutationError = (error: any) => {
    console.error(error)
    setIsError(true)
  }

  const [signUpLite, { loading: mutationLoading }] = useMutation<GqlSignUpLiteData, GqlSignUpLiteVars>(
    SIGN_UP_LITE,
    {
      onError: onMutationError
    }
  )

  const [companyInfoByVatId, {
    // loading: companyInfoByVatIdLoading,
    data: companyInfoByVatIdGql
  }] = useLazyQuery<GqlCompanyInfoByVatIdData, GqlCompanyInfoByVatIdVars>(
    COMPANY_INFO_BY_VAT_ID
  )

  /**
   * Handles form submission
   * @async onSubmit
   * @param {SignUpProps} variables - The form variables
   * @returns {Promise<void>}
   */
  const onSubmit = () => {
    signUpLite({
      variables: {
        email,
        password,
        vatId: vatId.length > 0 ? vatId : undefined,
        taxCodeType: vatInfo?.value ?? undefined,
        language: i18n.language,
        companyId: companySelected?.companyId,
        isMarketingNotificationsEnabled
      },
      onCompleted() {
        login({
          variables: {
            email,
            password,
            rememberMe: false
          },
          onCompleted() {
            window.location.replace(uri.getAppUri('explore'))
          }
        })
      }
    })
  }

  const debouncedVatId = useDebounce(vatId, 300)

  // Calling BE to get info about company
  useEffect(() => {
    if (debouncedVatId.length > 6) {
      companyInfoByVatId({
        variables: {
          vatId: debouncedVatId
        },
        onCompleted(data) {
          if (data.companyInfoByVatId.length === 1) {
            setCompanySelected(data.companyInfoByVatId[0])
          } else if (data.companyInfoByVatId.length === 0) {
            setCompanySelected(undefined)
          }
        }
      })
    } else {
      if (companySelected) {
        setCompanySelected(undefined)
      }
    }
  }, [debouncedVatId])

  const onChangeVatId = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setVatId(e.target.value)
  }

  const onClickRadioButton = (company: Company) => {
    setCompanySelected(company)
  }

  const switchCompanyNotCompany = (selection: boolean) => {
    setVatId('')
    setVatInfo(undefined)
    setIsVatVisible(selection)
  }

  // agreement links and translations
  const AgreementAndPrivacy = () => {
    const privacyLink = 'https://lite.matchplat.com/privacy-policy'
    const conditionLink = 'https://lite.matchplat.com/terms-and-conditions'

    return (
      i18n.language === 'it-IT'
        ? <Typography size='note'>Dichiaro di aver compiuto 14 anni e di avere letto e preso atto dell’informativa sul <a href={privacyLink} target='_blank' style={{ color: theme.colors.secondaryColor }} rel="noreferrer">trattamento dei dati personali</a> redatta ai sensi del Regolamento UE 679/2016 e presto il consenso all’utilizzo dei miei dati personali per l’invio di materiale di natura informativa promozionale e/o pubblicitaria (compresi gli eventi in programma), per effettuare ricerche di mercato, e per attività di vendita diretta sui servizi e prodotti offerti da Matchplat Srl. Dichiaro inoltre di aver letto e preso atto dei <a href={conditionLink} target='_blank' style={{ color: theme.colors.secondaryColor }} rel="noreferrer">termini e condizioni</a> di Matchplat.*</Typography>
        : <Typography size='note'>I confirm that I am of legal age and consent to the processing of personal data in accordance with EU regulations no. 679/2016. I hereby confirm that I have read the <a href={privacyLink} target='_blank' style={{ color: theme.colors.secondaryColor }} rel="noreferrer">privacy policy</a> and allow the treatment of the supplied data. I also declared that I’ve readed and and I do agree with the  Matchplat’s <a href={conditionLink} target='_blank' style={{ color: theme.colors.secondaryColor }} rel="noreferrer">terms and conditions</a>.*</Typography>
    )
  }

  // disabled button
  const checkIfSendIsDisabled = () => {
    const companyOrNot = isVatVisible ? (!vatId || companySelected === undefined) : false

    return (!isUserAgreement || !email || !password || companyOrNot)
  }

  return (
    <Box column width={mediaUpFrom('md') ? '65%' : '95%'}>
      <Typography size="title" weight='bold'>
        {t('translations:titles.signupForm')}
      </Typography>
      <Spacer vertical />
      {/* EMAIL */}
      <Input
        textSize='subArticle'
        label={t('common:form.email') + '*'}
        labelSize={mediaUpFrom('md') ? 'article' : 'subArticle'}
        placeholder="b2b@matchplat.com"
        type="email"
        value={email}
        onChange={(e: any) => setEmail(e.target.value)}
      />
      {/* PASSWORD */}
      <Input
        textSize='subArticle'
        label={t('common:form.password') + '*'}
        labelSize={mediaUpFrom('md') ? 'article' : 'subArticle'}
        icon={{
          icon: showPassword ? 'eyeSlash' : 'eye',
          iconPosition: 'right',
          onClickIcon: () => setShowPassword(prev => !prev),
          isClickable: true
        }}
        placeholder="password"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(e: any) => setPassword(e.target.value)}
      />
      {/* SELECT COMPANY NOT COMPANY */}
      <Box>
        <Radio
          name="vatSelection"
          label={t('common:form.company')}
          isChecked={isVatVisible}
          onClick={() => switchCompanyNotCompany(true)}
        />
        <Spacer />
        <Radio
          name="vatSelection"
          label={t('common:form.not_company')}
          isChecked={!isVatVisible}
          onClick={() => switchCompanyNotCompany(false)}
        />
      </Box>
      <Spacer vertical half />
      {/* SELECT VAT TYPE */}
      {isVatVisible &&
        <>
          <SelectCountriesVat
            setPlaceholderVatInfo={setPlaceholderVatInfo}
            setVatInfo={setVatInfo}
            vatInfo={vatInfo}
          />
          {vatInfo?.value &&
            <Input
              isDisabled={!vatInfo?.value}
              labelSize={mediaUpFrom('md') ? 'article' : 'subArticle'}
              textSize='subArticle'
              value={vatId}
              placeholder={placeholderVatInfo}
              onChange={(e: any) => onChangeVatId(e)}
            />
          }
        </>
      }
      {/* VAT ID */}
      {(companyInfoByVatIdGql && vatId) &&
        (companyInfoByVatIdGql.companyInfoByVatId.length > 0
          ? (companyInfoByVatIdGql.companyInfoByVatId.length > 1
            ? <Box column>
              <Card>
                {companyInfoByVatIdGql.companyInfoByVatId.map((company, index) =>
                  <Radio
                    key={index}
                    name='companies'
                    label={company.companyInternationalName + ', ' + company.companyCity}
                    labelSize="subArticle"
                    onClick={() => onClickRadioButton(company)}
                  />
                )}
              </Card>
              <Spacer vertical />
            </Box>
            : <>
              <Input
                isDisabled
                label={t('common:form.company_name')}
                labelSize={mediaUpFrom('md') ? 'article' : 'subArticle'}
                textSize='subArticle'
                value={companyInfoByVatIdGql.companyInfoByVatId[0].companyInternationalName}
              />
              <Input
                isDisabled
                label={t('common:form.company_city')}
                labelSize={mediaUpFrom('md') ? 'article' : 'subArticle'}
                textSize='subArticle'
                value={companyInfoByVatIdGql.companyInfoByVatId[0].companyCity}
              />
            </>
          )
          : <></>
        )
      }
      {/* AGREEMENT AND MARKETING */}
      <Checkbox
        label={<AgreementAndPrivacy />}
        isChecked={isUserAgreement}
        onClick={() => setIsUserAgreement(prev => !prev)}
      />
      <Checkbox
        label={t('common:form.marketing_check')}
        isChecked={isMarketingNotificationsEnabled}
        onClick={() => setIsMarketingNotificationsEnabled(prev => !prev)}
      />
      {/* SEND BUTTON */}
      <Button
        color="secondary"
        isBlock
        isFull
        noMargin
        textAlign='center'
        text={t('common:button.send')}
        isDisabled={checkIfSendIsDisabled()}
        isLoading={mutationLoading}
        isError={isError}
        doneText={t('common:button.done')}
        errorText={t('common:button.error')}
        onClick={() => onSubmit()}
      />
      <Spacer vertical half />
      <Box column alignItems='flex-end'>
        <Typography size="subArticle" style={{ cursor: 'pointer' }} color='secondary' onClick={() => navigate('/')}>{t('translations:actions.signIn')}</Typography>
      </Box>
    </Box>
  )
}

export default SignUp
